import React, {useEffect, useState} from "react";
import {FieldBlock, FormLayoutRows, ModalConfirmMessage} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import ButtonAddPaymentMethod from "../action/ButtonAddPaymentMethod";
import ModalAddPaymentMethod from "../../modal/ModalAddPaymentMethod";
import {BillingPaymentMethod} from "../../../api/model/billing/BillingPaymentMethod";
import {BillingPaymentMethodStatus} from "../../../api/model/billing/BillingPaymentMethodStatus";
import {handleCatchError} from "../../../common/utils/helper";
import {PaymentMethodDisplay} from "../../widget";
import {session} from "../../../api/ApiSession";
import {TranslationPortalFile} from "../../../utils/constants";

type FormChangePaymentMethodProps = {
    showPaymentInfo: boolean;
};

const FormChangePaymentMethod: React.FC<FormChangePaymentMethodProps> = ({showPaymentInfo}) => {
    const {t: textBillingInfo} = useTranslation(TranslationPortalFile.BILLING_INFO);
    const [paymentMethods, setPaymentMethods] = useState<BillingPaymentMethod[]>([]);
    const [isShowModalConfirm, setShowModalConfirm] = useState(false);
    const [isShowModalAddPaymentMethod, setShowModalAddPaymentMethod] = useState(false);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setPaymentMethods(await session.restBilling.getPaymentMethods());
            } catch (e) {
                handleCatchError(e, (e) => console.error("Failed to load payment methods", e.message));
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    if (isLoading) return <></>;

    const handleConfirmChangePaymentMethod = () => {
        setShowModalConfirm(false);
        setShowModalAddPaymentMethod(true);
    };

    const paymentMethod = paymentMethods
        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
        .find((it) => it.status === BillingPaymentMethodStatus.VALIDATED);

    return (
        <FieldBlock
            label={textBillingInfo("label.payment_method")}
            error={(showPaymentInfo && !paymentMethod) ? textBillingInfo("tooltip.no_payment_method") : undefined}
        >
            <FormLayoutRows>
                {paymentMethod
                    ? <PaymentMethodDisplay paymentMethod={paymentMethod} onChange={() => setShowModalConfirm(true)}/>
                    : <ButtonAddPaymentMethod onClick={() => setShowModalAddPaymentMethod(true)}/>
                }
            </FormLayoutRows>
            <ModalConfirmMessage
                active={isShowModalConfirm}
                cancel={{onClick: () => setShowModalConfirm(false)}}
                confirm={{onClick: handleConfirmChangePaymentMethod}}
                message={textBillingInfo("message.confirm_change_payment_method")}
            />
            <ModalAddPaymentMethod
                title={textBillingInfo(`modal.${paymentMethod ? "change" : "add"}_payment_method.title`)}
                active={isShowModalAddPaymentMethod}
                onClose={() => setShowModalAddPaymentMethod(false)}
            />
        </FieldBlock>
    );
};

export default FormChangePaymentMethod;
