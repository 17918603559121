import React, {useEffect, useState} from "react";
import {AlertSeverity, Box, BoxSpacing, ButtonLinkCancel, ButtonSave, FieldBlock, FormLayoutButtons, FormLayoutRows, FormLayoutTitle, SelectAutocomplete} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import getApiErrorTranslationKey from "../../../api/model/ApiErrors";
import {AccountType} from "../../../common/api/model/account/AccountType";
import {Account} from "../../../common/api/model/account/Account";
import {ButtonUpdate} from "../index";
import {Country, CountryCode} from "../../../common/api/model/Country";
import {handleCatchError} from "../../../common/utils/helper";
import {PartnerAccount} from "../../../api/model/account/PartnerAccount";
import {session} from "../../../api/ApiSession";
import {TranslationPortalFile} from "../../../utils/constants";
import {UIEventManager} from "../../../common/utils/UIEventManager";

type FormChangePartnerInfoProps = {
    loggedAccount: Account;
    initPartnerAccount: PartnerAccount;
};

const FormChangePartnerInfo: React.FC<FormChangePartnerInfoProps> = ({loggedAccount, initPartnerAccount}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textInformation} = useTranslation(TranslationPortalFile.INFORMATION);

    const [countries, setCountries] = useState<Country[]>([]);
    const [countryId, setCountryId] = useState<number>(initPartnerAccount.partner.country_id);
    const [infoForm, setInfoForm] = useState<{ country_id: number }>({country_id: initPartnerAccount.partner.country_id});

    const [isOpenForm, setOpenForm] = useState(false);

    useEffect(() => {
        (async function () {
            try {
                const countries = await session.getCountries();
                setCountries(countries);
            } catch (e) {
                handleCatchError(e, (e) => console.error("Failed to load countries", e.message));
            }
        })();
    }, []);

    const handleSubmit = async () => {
        try {
            const sessionPartnerAccount = await session.getPartnerAccount();

            const newPartnerAccount = new PartnerAccount({
                ...sessionPartnerAccount,
                partner: {
                    ...sessionPartnerAccount.partner,
                    country_id: +infoForm.country_id
                }
            });

            await session.restPartnerAccount.update(newPartnerAccount);
            session.setPartnerAccount(newPartnerAccount);

            setCountryId(+infoForm.country_id);
            setOpenForm(false);
            UIEventManager.alert(textInformation("message.submit_success"), AlertSeverity.SUCCESS);
        } catch (e) {
            handleCatchError(e, (e) => UIEventManager.alert(t(`error.${getApiErrorTranslationKey(e.message)}`), AlertSeverity.DANGER));
        }
    };

    const handleCancel = () => {
        setInfoForm({country_id: countryId});
        setOpenForm(false);
    };

    const currentCountry = countries.find((country) => country.id === infoForm.country_id);

    return (
        <Box spacing={BoxSpacing.LARGE}>
            <FormLayoutRows>
                <FormLayoutTitle>{textInformation("partner_info_form.title")}</FormLayoutTitle>
                {isOpenForm ?
                    <>
                        <FieldBlock label={textInformation("partner_info_form.field.id")}>
                            {initPartnerAccount.partner.id?.toString() || ""}
                        </FieldBlock>
                        <FieldBlock label={textInformation("partner_info_form.field.company")}>
                            {initPartnerAccount.partner.company}
                        </FieldBlock>
                        <FieldBlock label={textInformation("partner_info_form.field.country")}>
                            <SelectAutocomplete
                                onChange={(option) => setInfoForm((prev) => ({...prev, country_id: option?.value as number}))}
                                options={countries.map((country) => ({value: country.id, label: country.name}))}
                                value={currentCountry?.id || CountryCode.FRANCE.id}
                            />
                        </FieldBlock>
                        <FormLayoutButtons>
                            <ButtonLinkCancel onClick={handleCancel}/>
                            <ButtonSave onClick={handleSubmit} disabled={currentCountry?.id === countryId}/>
                        </FormLayoutButtons>
                    </> :
                    <>
                        <FieldBlock label={textInformation("partner_info_form.field.id")}>
                            {initPartnerAccount.partner.id?.toString() || ""}
                        </FieldBlock>
                        <FieldBlock label={textInformation("partner_info_form.field.company")}>
                            {initPartnerAccount.partner.company}
                        </FieldBlock>
                        <FieldBlock label={textInformation("partner_info_form.field.country")}>
                            {currentCountry?.name || ""}
                        </FieldBlock>
                        {loggedAccount.type === AccountType.OWNER &&
                            <FormLayoutButtons>
                                <ButtonUpdate onClick={() => setOpenForm(true)}/>
                            </FormLayoutButtons>
                        }
                    </>
                }
            </FormLayoutRows>
        </Box>
    );
};

export default FormChangePartnerInfo;
