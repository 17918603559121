import {AlertSeverity, Box, BoxSpacing, ButtonLink, ButtonLinkStyle, ButtonSave, ContentBlock, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, FormLayoutButtons, FormLayoutRows, RadioButton} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import getApiErrorTranslationKey from "../../api/model/ApiErrors";
import useRegister from "../../utils/hooks/useRegister";
import {FormLayoutMessage} from "../../common/component/snippet";
import {handleCatchError} from "../../common/utils/helper";
import {HeardAboutEvent} from "../../api/model/portal-event/HeardAboutEvent";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {LogoSirdataOnboarding} from "../../component/snippet";
import {pathRegister, pathRegisterOrigin, TranslationPortalFile} from "../../utils/constants";
import {PortalEventRelatedType} from "../../common/api/model/portal/PortalEvent";
import {session} from "../../api/ApiSession";

function RegisterOrigin() {
    const navigate = useNavigate();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textOnboarding} = useTranslation(TranslationPortalFile.ONBOARDING);
    const [registerer, {goToNext}] = useRegister(pathRegisterOrigin);

    const [value, setValue] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState("");
    const heardAboutList = session.onboarding.heard_about_list || [];

    useEffect(() => {
        if (!registerer.isOriginStepAllowed) {
            navigate(pathRegister, {replace: true});
        }
    }, [navigate, registerer.isOriginStepAllowed]);

    const handleSubmit = async () => {
        try {
            setSubmitting(true);
            const account = await session.getPartnerAccount();
            const event = new HeardAboutEvent({related_id: account.id.toString(), related_type: PortalEventRelatedType.PARTNER_ACCOUNT}).withData({value, from_origin: session.origin.name});

            await session.restPortal.pushEvent(event);
            goToNext();
        } catch (e) {
            handleCatchError(e, (e) => {
                if (e.statusCode === HttpStatusCode.UNAUTHORIZED) {
                    navigate(pathRegister, {replace: true});
                } else {
                    setApiErrorMessage(t(`error.${getApiErrorTranslationKey(e.message)}`));
                }
            });
            setSubmitting(false);
        }
    };

    return (
        <div className="wrapper onboarding">
            <div className="register-page">
                <div className="register-form">
                    <div className="main-logo">
                        <LogoSirdataOnboarding/>
                    </div>
                    <div className="h1 title" dangerouslySetInnerHTML={{__html: textOnboarding("register_origin.title", {step: session.onboarding.getCurrentStep(pathRegisterOrigin), steps: session.onboarding.getTotalSteps()})}}/>
                    <div className="description">{textOnboarding("register_origin.description")}</div>
                    <ContentBlock cssClass="register-form__block">
                        <Box spacing={BoxSpacing.XLARGE}>
                            <FormLayoutRows>
                                {Array.from(heardAboutList).map(([group, items]) =>
                                    <div key={group} className="register-form__origin-group">
                                        <h3>{textOnboarding(`register_origin.label.${group}`)}</h3>
                                        <div className="register-form__origin-items">
                                            {items.map((it) =>
                                                <RadioButton
                                                    key={it}
                                                    id={it}
                                                    value={it}
                                                    checked={value === it}
                                                    label={textOnboarding(`register_origin.origin.${it}`)}
                                                    onChange={() => setValue(it)}
                                                />)
                                            }
                                        </div>
                                    </div>
                                )}
                                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL} alignment={FlexContentAlignment.CENTER}>
                                    <FormLayoutButtons>
                                        <ButtonSave disabled={!value} onClick={handleSubmit} loading={isSubmitting}/>
                                        {session.onboarding.isStepSkippable(pathRegisterOrigin) &&
                                            <ButtonLink style={ButtonLinkStyle.MIDNIGHT_LIGHT} onClick={goToNext}>
                                                {textOnboarding("form.button.ignore_step")}
                                            </ButtonLink>
                                        }
                                    </FormLayoutButtons>
                                    {apiErrorMessage &&
                                        <FormLayoutMessage message={apiErrorMessage} severity={AlertSeverity.DANGER}/>
                                    }
                                </FlexContent>
                            </FormLayoutRows>
                        </Box>
                    </ContentBlock>
                </div>
            </div>
        </div>
    );
}

export default RegisterOrigin;
