import React, {Dispatch, FormEvent, SetStateAction, useCallback, useState} from "react";
import {Alert, AlertSeverity, FieldBlock, Form, FormLayoutRows} from "@sirdata/ui-lib";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useTranslation} from "react-i18next";
import {handleCatchError} from "../../common/utils/helper";
import {searchParamSuccess, TranslationPortalFile} from "../../utils/constants";
import {session} from "../../api/ApiSession";
import {stripePaymentElementOptions} from "../../utils/stripe";

type PaymentMethodFormProps = {
    onSubmit: Dispatch<SetStateAction<boolean>>;
    onSuccess: () => void;
}

export const PAYMENT_METHOD_FORM_ID = "payment-method-form";

const PaymentMethodForm: React.FC<PaymentMethodFormProps> = ({onSubmit, onSuccess}) => {
    const stripe = useStripe();
    const elements = useElements();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);

    const [apiErrorMessage, setApiErrorMessage] = useState("");

    const handleSubmit = useCallback(async (event: FormEvent) => {
        event.preventDefault();
        setApiErrorMessage("");
        onSubmit(true);

        if (!stripe || !elements) {
            return;
        }

        const {error: submitError} = await elements.submit();
        if (submitError) {
            onSubmit(false);
            return;
        }

        try {
            const loggedAccount = await session.getAccount();
            const partnerBillingInfo = await session.restBilling.getPartnerBillingInfo();
            const setupPaymentMethod = await session.restBilling.setupPaymentMethodStripe();

            const {setupIntent} = await stripe.confirmSetup({
                elements: elements,
                clientSecret: setupPaymentMethod.client_secret,
                confirmParams: {
                    return_url: window.location.href + searchParamSuccess,
                    payment_method_data: {
                        billing_details: {
                            email: loggedAccount.email,
                            address: {
                                line1: partnerBillingInfo.address,
                                line2: null,
                                city: partnerBillingInfo.city,
                                country: null,
                                postal_code: partnerBillingInfo.postal_code,
                                state: null
                            }
                        }
                    }
                },
                redirect: "if_required"
            });

            if (setupIntent && setupIntent.status === "succeeded") {
                onSuccess();
                return;
            }

            setApiErrorMessage(t("error.stripe.confirm_setup_failed"));
        } catch (e) {
            handleCatchError(e, (e) => setApiErrorMessage(t(`error.${e.statusCode === 409 ? "payment_method_already_exist" : "default"}`)));
        } finally {
            onSubmit(false);
        }
    }, [stripe, elements, onSubmit, onSuccess, t]);

    return (
        <FormLayoutRows>
            {apiErrorMessage &&
                <Alert text={apiErrorMessage} severity={AlertSeverity.DANGER}/>
            }
            <Form id={PAYMENT_METHOD_FORM_ID} onSubmit={handleSubmit}>
                <FieldBlock label={t("label.payment_method")} tooltip={t("tooltip.payment_method")}>
                    <PaymentElement options={stripePaymentElementOptions}/>
                </FieldBlock>
            </Form>
        </FormLayoutRows>
    );
};

export default PaymentMethodForm;
