import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Alert, AlertSeverity, Box, BoxSpacing, ContentBlock, LayoutColumns, Loadable} from "@sirdata/ui-lib";

import FormChangeBillingEmail from "./FormChangeBillingEmail";
import FormChangeBillingInfo from "./FormChangeBillingInfo";
import FormChangePaymentMethod from "./FormChangePaymentMethod";
import getApiErrorTranslationKey from "../../../api/model/ApiErrors";
import {Account} from "../../../common/api/model/account/Account";
import {BillingInfo} from "../../../api/model/billing/BillingInfo";
import {BillingInfoField} from "../../../api/model/billing/BillingInfoField";
import {handleCatchError} from "../../../common/utils/helper";
import {HttpStatusCode} from "../../../common/api/http/HttpStatusCode";
import {LicenseName} from "../../../api/model/account/LicenseName";
import {Origin} from "../../../common/api/model/Origin";
import {LicensedProduct} from "../../../api/model/account/LicensedProduct";
import {session} from "../../../api/ApiSession";
import {TranslationPortalFile} from "../../../utils/constants";
import {UIEventManager} from "../../../common/utils/UIEventManager";

const FormChangeBilling: React.FC = () => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textBillingInfo} = useTranslation(TranslationPortalFile.BILLING_INFO);

    const [loggedAccount, setLoggedAccount] = useState<Account>();
    const [billingInfo, setBillingInfo] = useState<BillingInfo>(new BillingInfo());

    const [isLoading, setLoading] = useState(false);
    const [isShowPaymentInfo, setShowPaymentInfo] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setLoggedAccount(await session.getAccount());

                const account = await session.getPartnerAccount();
                const partnerBillingInfo = await session.restBilling.getPartnerBillingInfo();
                setBillingInfo(new BillingInfo({...partnerBillingInfo, [BillingInfoField.COMPANY_NAME]: partnerBillingInfo.company_name || account.partner.company}));

                const partnerLicenses = await session.restLicense.list();
                const activePartnerLicenseNames = partnerLicenses.filter((it) => it.active).map((it) => it.license.name as LicenseName);
                const isShowPaymentInfo = activePartnerLicenseNames.some((name) => LicensedProduct.getLicenseByName(name)?.isPaymentInfoRequired);
                setShowPaymentInfo(isShowPaymentInfo);
            } catch (e) {
                handleCatchError(e, (e) => {
                    if (e.statusCode !== HttpStatusCode.NOT_FOUND) {
                        UIEventManager.alert(t(`error.${getApiErrorTranslationKey(e.message)}`), AlertSeverity.DANGER);
                    }
                });
            } finally {
                setLoading(false);
            }
        })();
    }, [t]);

    if (!loggedAccount) return <></>;

    return (
        <Loadable loading={isLoading}>
            {(loggedAccount.services.length === 2 && loggedAccount.services.includes(Origin.AUDIENCE.service)) &&
                <Alert text={textBillingInfo("message.audience_user_only")} severity={AlertSeverity.WARNING}/>
            }
            <LayoutColumns>
                <ContentBlock>
                    <Box spacing={BoxSpacing.LARGE}>
                        <FormChangeBillingInfo
                            account={loggedAccount}
                            billingInfo={billingInfo}
                            showPaymentInfo={isShowPaymentInfo}
                            onUpdate={setBillingInfo}
                        />
                    </Box>
                </ContentBlock>
                <ContentBlock>
                    <Box spacing={BoxSpacing.LARGE}>
                        <FormChangePaymentMethod showPaymentInfo={isShowPaymentInfo}/>
                    </Box>
                    <Box spacing={BoxSpacing.LARGE}>
                        <FormChangeBillingEmail billingInfo={billingInfo} onUpdate={setBillingInfo}/>
                    </Box>
                </ContentBlock>
            </LayoutColumns>
        </Loadable>
    );
};

export default FormChangeBilling;
