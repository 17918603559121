import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {AlertSeverity, ButtonLinkCancel, ButtonSave, ButtonSize, FieldBlock, FormLayoutButtons, FormLayoutColumns, FormLayoutMention, FormLayoutRows, InputText, SelectAutocomplete} from "@sirdata/ui-lib";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import ButtonUpdate from "../action/ButtonUpdate";
import getApiErrorTranslationKey from "../../../api/model/ApiErrors";
import {Account} from "../../../common/api/model/account/Account";
import {BillingInfo} from "../../../api/model/billing/BillingInfo";
import {BillingInfoField} from "../../../api/model/billing/BillingInfoField";
import {Country, CountryCode} from "../../../common/api/model/Country";
import {detectChanges} from "../../../common/utils/portal";
import {EmailNotificationType} from "../../../common/api/model/email/EmailNotificationType";
import {handleCatchError} from "../../../common/utils/helper";
import {Origin} from "../../../common/api/model/Origin";
import {session} from "../../../api/ApiSession";
import {TranslationPortalFile} from "../../../utils/constants";
import {UIEventManager} from "../../../common/utils/UIEventManager";

type FormChangeBillingInfoProps = {
    account: Account;
    billingInfo: BillingInfo;
    showPaymentInfo: boolean;
    onUpdate: Dispatch<SetStateAction<BillingInfo>>;
};

const FormChangeBillingInfo: React.FC<FormChangeBillingInfoProps> = ({account, billingInfo: initBillingInfo, showPaymentInfo, onUpdate}) => {
    const navigate = useNavigate();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textBillingInfo} = useTranslation(TranslationPortalFile.BILLING_INFO);

    const [billingInfo, setBillingInfo] = useState<BillingInfo>(new BillingInfo());
    const [countries, setCountries] = useState<Country[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isEditing, setEditing] = useState(false);

    const loadCountries = useCallback(async () => {
        try {
            setCountries(await session.getCountries());
        } catch (e) {
            handleCatchError(e, (e) => console.error("Failed to load countries", e.message));
        }
    }, []);

    useEffect(() => {
        loadCountries();
        setBillingInfo(initBillingInfo);
    }, [initBillingInfo, loadCountries]);

    const handleSubmit = async () => {
        try {
            setSubmitting(true);
            await session.restBilling.updatePartnerBillingInfo(billingInfo);
            onUpdate(billingInfo);

            [Origin.CMP, Origin.CUSTOMER, Origin.GTM_SERVER_SIDE, Origin.HELPER].forEach(async (origin) => {
                if (account?.hasAccess(origin.name)) {
                    await session.restPortal.sendEmailNotification(EmailNotificationType.BILLING_INFO_UPDATE, origin);
                }
            });

            if ((!account.billing_status.freemium && !account.billing_status.premium) || (showPaymentInfo && !account.billing_status.premium)) {
                // this condition probably deprecated because of billing infos are not skippable during the registration anymore
                navigate(0);
            } else {
                UIEventManager.alert(t("message.update_success"), AlertSeverity.SUCCESS);
            }
        } catch (e) {
            handleCatchError(e, (e) => UIEventManager.alert(t(`error.${getApiErrorTranslationKey(e.message)}`), AlertSeverity.DANGER));
        } finally {
            setSubmitting(false);
            setEditing(false);
        }
    };

    const handleChangeBillingInfo = (field: BillingInfoField, value: string) => {
        setBillingInfo((prevState) => new BillingInfo({...prevState, [field]: value}));
    };

    const handleCancel = () => {
        setBillingInfo(initBillingInfo);
        setEditing(false);
    };

    const hasEmptyField = () => {
        return !billingInfo.company_name || !billingInfo.contact_firstname || !billingInfo.contact_lastname
            || !billingInfo.address || !billingInfo.postal_code || !billingInfo.city || !billingInfo.vat || !billingInfo.business_id;
    };

    const currentCountry = countries.find((c) => c.isocode === billingInfo.country_iso);

    return (
        <FormLayoutRows>
            <FieldBlock
                name={BillingInfoField.COMPANY_NAME}
                label={textBillingInfo(`field.${BillingInfoField.COMPANY_NAME}`)}
                required
            >
                <InputText
                    value={billingInfo.company_name}
                    placeholder={textBillingInfo(`placeholder.${BillingInfoField.COMPANY_NAME}`)}
                    onChange={(value) => handleChangeBillingInfo(BillingInfoField.COMPANY_NAME, value)}
                    disabled={!isEditing}
                    autoFocus
                />
            </FieldBlock>
            <FormLayoutColumns>
                <FieldBlock
                    name={BillingInfoField.CONTACT_FIRSTNAME}
                    label={textBillingInfo(`field.${BillingInfoField.CONTACT_FIRSTNAME}`)}
                    required
                >
                    <InputText
                        value={billingInfo.contact_firstname}
                        placeholder={textBillingInfo(`placeholder.${BillingInfoField.CONTACT_FIRSTNAME}`)}
                        onChange={(value) => handleChangeBillingInfo(BillingInfoField.CONTACT_FIRSTNAME, value)}
                        disabled={!isEditing}
                    />
                </FieldBlock>
                <FieldBlock
                    name={BillingInfoField.CONTACT_LASTNAME}
                    label={textBillingInfo(`field.${BillingInfoField.CONTACT_LASTNAME}`)}
                    required
                >
                    <InputText
                        value={billingInfo.contact_lastname}
                        placeholder={textBillingInfo(`placeholder.${BillingInfoField.CONTACT_LASTNAME}`)}
                        onChange={(value) => handleChangeBillingInfo(BillingInfoField.CONTACT_LASTNAME, value)}
                        disabled={!isEditing}
                    />
                </FieldBlock>
            </FormLayoutColumns>
            <FieldBlock
                name={BillingInfoField.ADDRESS}
                label={textBillingInfo(`field.${BillingInfoField.ADDRESS}`)}
                required
            >
                <InputText
                    value={billingInfo.address}
                    placeholder={textBillingInfo(`placeholder.${BillingInfoField.ADDRESS}`)}
                    onChange={(value) => handleChangeBillingInfo(BillingInfoField.ADDRESS, value)}
                    disabled={!isEditing}
                />
            </FieldBlock>
            <FormLayoutColumns>
                <FieldBlock
                    name={BillingInfoField.POSTAL_CODE}
                    label={textBillingInfo(`field.${BillingInfoField.POSTAL_CODE}`)}
                    required
                >
                    <InputText
                        value={billingInfo.postal_code}
                        placeholder={textBillingInfo(`placeholder.${BillingInfoField.POSTAL_CODE}`)}
                        onChange={(value) => handleChangeBillingInfo(BillingInfoField.POSTAL_CODE, value)}
                        disabled={!isEditing}
                    />
                </FieldBlock>
                <FieldBlock
                    name={BillingInfoField.CITY}
                    label={textBillingInfo(`field.${BillingInfoField.CITY}`)}
                    required
                >
                    <InputText
                        value={billingInfo.city}
                        placeholder={textBillingInfo(`placeholder.${BillingInfoField.CITY}`)}
                        onChange={(value) => handleChangeBillingInfo(BillingInfoField.CITY, value)}
                        disabled={!isEditing}
                    />
                </FieldBlock>
            </FormLayoutColumns>
            <FormLayoutColumns>
                <FieldBlock
                    name={BillingInfoField.COUNTRY}
                    label={textBillingInfo(`field.${BillingInfoField.COUNTRY}`)}
                    required
                >
                    <SelectAutocomplete
                        value={currentCountry?.isocode || CountryCode.FRANCE.code}
                        options={countries.map((country) => ({value: country.isocode, label: country.name}))}
                        onChange={(option) => handleChangeBillingInfo(BillingInfoField.COUNTRY, option?.value as string)}
                        disabled={!isEditing}
                    />
                </FieldBlock>
                <FieldBlock
                    name={BillingInfoField.VAT}
                    label={textBillingInfo(`field.${BillingInfoField.VAT}`)}
                    required
                >
                    <InputText
                        value={billingInfo.vat}
                        placeholder={textBillingInfo(`placeholder.${BillingInfoField.VAT}`)}
                        onChange={(value) => handleChangeBillingInfo(BillingInfoField.VAT, value)}
                        disabled={!isEditing}
                    />
                </FieldBlock>
            </FormLayoutColumns>
            <FormLayoutColumns columns={2}>
                <FieldBlock
                    name={BillingInfoField.BUSINESS_ID}
                    label={textBillingInfo(`field.${BillingInfoField.BUSINESS_ID}`)}
                    required
                >
                    <InputText
                        value={billingInfo.business_id}
                        placeholder={textBillingInfo(`placeholder.${BillingInfoField.BUSINESS_ID}`)}
                        onChange={(value) => handleChangeBillingInfo(BillingInfoField.BUSINESS_ID, value)}
                        disabled={!isEditing}
                    />
                </FieldBlock>
            </FormLayoutColumns>
            <FormLayoutMention/>
            <FormLayoutButtons>
                {isEditing
                    ? (
                        <>
                            <ButtonLinkCancel onClick={handleCancel}/>
                            <ButtonSave
                                size={ButtonSize.MEDIUM}
                                onClick={handleSubmit}
                                disabled={hasEmptyField() || !detectChanges(billingInfo, initBillingInfo)}
                                loading={isSubmitting}
                            />
                        </>
                    ) : (
                        <ButtonUpdate onClick={() => setEditing(true)}/>
                    )
                }
            </FormLayoutButtons>
        </FormLayoutRows>
    );
};

export default FormChangeBillingInfo;
