import {loadStripe, StripeElementLocale, StripeElementsOptions, StripePaymentElementOptions} from "@stripe/stripe-js";
import {Locale} from "../common/utils/Locale";
import {usePreprod} from "../common/utils/helper";

const stripePublicKey = usePreprod
    ? "pk_test_51PJ9T5GRj5mDXrAkCleFBLb7ateDTQaU5ThnHpRnh74XbDnNJHhfHTIuAvt1A7FHYhZ9ftapLg0Sru0fIS53lwsH00SGqmYORw"
    : "pk_live_51PJ9T5GRj5mDXrAkzjIMekRq470mdXMhQROv1JQcgbexjOvEKicqForSwNi9LjHoIg7Aby0zl8dVr2LndrNXwQYj00BgzR6ir1";

export const stripePromise = loadStripe(stripePublicKey);

export const stripeProviderOptions: StripeElementsOptions = {
    mode: "setup",
    currency: "eur",
    locale: Locale.getCurrentLocale() as StripeElementLocale,
    fonts: [{
        cssSrc: "https://cdn.sirdata.io/front/fonts/nunito/Nunito.css"
    }],
    appearance: {
        theme: "stripe",
        variables: {
            colorPrimary: "#6ed216",
            colorText: "#202942",
            colorDanger: "#f41542",
            colorTextPlaceholder: "#ADB5BD",
            fontFamily: "Nunito, sans-serif",
            fontSmooth: "antialiased",
            fontLineHeight: "1.5",
            borderRadius: "12px",
            iconColor: "#5a6d90",
            tabIconHoverColor: "#5a6d90",
            gridRowSpacing: "1rem"
        },
        rules: {
            ".Label": {
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "1.4"
            },
            ".Tab": {
                color: "var(--colorText)",
                borderColor: "#e9ecef",
                boxShadow: "none"
            },
            ".Tab--selected": {
                boxShadow: "var(--colorPrimary) 0 0 0 1px"
            },
            ".Tab--selected:focus": {
                boxShadow: "var(--colorPrimary) 0 0 0 1px"
            },
            ".Tab--selected:hover": {
                backgroundColor: "var(--colorBackground)"
            },
            ".Tab:hover": {
                backgroundColor: "#f8f9fc"
            },
            ".Tab:active": {
                boxShadow: "var(--colorPrimary) 0 0 0 1px"
            },
            ".Input": {
                boxShadow: "none",
                borderColor: "#e9ecef",
                color: "#5A6D90"
            },
            ".Input:focus": {
                borderColor: "var(--colorPrimary)",
                boxShadow: "var(--colorPrimary) 0 0 0 1px"
            },
            ".TermsText": {
                fontSize: ".85rem"
            }
        }
    }
};

export const stripePaymentElementOptions: StripePaymentElementOptions = {
    layout: {
        type: "tabs",
        defaultCollapsed: false
    },
    fields: {
        billingDetails: {
            address: "never",
            email: "never"
        }
    }
};
